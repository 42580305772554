import React from "react";
import ReactDOM from "react-dom";
import { CloseIcon } from "./AppIcon";

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  firstBtnTitle,
  secondBtnTitle,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="flex justify-between">
          <h2 className="mt-0 themecolor font-medium">{title}</h2>
          <span onClick={onClose} className="cursor-pointer">
            <CloseIcon />
          </span>
        </div>

        {children}
        <div className="mt-20 flex">
          {!firstBtnTitle ? null : (
            <button onClick={onClose} className="prmiary-btn">
              {firstBtnTitle}
            </button>
          )}
          {!secondBtnTitle ? null : (
            <button onClick={onClose} className="prmiary-btn">
              {secondBtnTitle}
            </button>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default Modal;
