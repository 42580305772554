import React, { useEffect, useRef, useState } from "react";
import { Client } from "paho-mqtt";
import Dashboard from "./Dashboard";
import { toast } from "react-toastify";

const mqttBroker = "65.0.216.215"; // Replace with your MQTT broker URL and port
const port = 1883;
const mqttTopic = "Fuel_Data"; // Replace with your MQTT topic

const FuelComponent = () => {
  const [data, setData] = useState({ });
  // 7: { amount: 20, volume: 20 }
  const clientRef = useRef(null);

  useEffect(() => {
    const client = new Client(mqttBroker, Number(port), "mqtt");

    client.connect({ onSuccess: onConnect });
    client.onMessageArrived = onMessageArrived;
    clientRef.current = client;

    function onConnect() {
      // toast.success("Connected to MQTT broker");
      client.subscribe(mqttTopic);
    }

    function onMessageArrived(message) {
      const res = JSON.parse(message.payloadString);
      console.log("Connected to MQTT brokers", res);
      // res={id:7,amount:000000000000.00}
      setData({ [res.id]: res });
    }
    // Event listener for successful connection

    // client.onConnectionLost = (responseObject) => {
    //   toast.error("MQTT client connection lost:");

    // };

    return () => {
      client.disconnect();
    };
  }, []);

  return (
    <div className="container">
      <Dashboard clientRef={clientRef} data={data} />
    </div>
  );
};

export default FuelComponent;
