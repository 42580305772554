import { useState } from "react";
import { toast } from "react-toastify";
import PriceSetIcon from "../src/images/price.svg";
import FuelStart from "../src/images/fuelstart.svg";
import ReadPriceIcon from "../src/images/read_price.svg";
import ReadTotalAccIcon from "../src/images/read_total_accumulation.svg";
import ReadDeleteShiftIcon from "../src/images/shift_total.svg";
import ReadStateIcon from "../src/images/read_state.svg";
import GetFuelingDataIcon from "../src/images/fueling_data.svg";
import GetDateTimeIcon from "../src/images/get_Date_Time.svg";
import PauseSolenoidValveIcon from "../src/images/PauseSolenoidValveIcon.svg";
import ResumeSolenoidValveIcon from "../src/images/ResumeSolenoidValveIcon.svg";
import Modal from "./Modal";
import MQTT from "paho-mqtt";

function Dashboard({ clientRef, data = {} }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalLabel, setModalLabel] = useState("");
  const [fueling, setFueling] = useState(false);
  const [fuelPrice, setFuelPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);

  const sendMessage = (callId, value, type = "") => {
    const msg = { id: callId };
    if (value) {
      if (type === "volume") {
        msg["volume"] = parseFloat(value);
      }
      if (type === "amount") {
        msg["amount"] = parseFloat(value);
      }
    }

    if (!clientRef.current || !clientRef.current.isConnected()) {
      console.warn("MQTT client is not connected. Cannot send message.");
      // toast.error("MQTT client connection lost:");
      return;
    }

    const message = new MQTT.Message(JSON.stringify(msg));
    message.destinationName = "fuel";
    clientRef.current.send(message);
  };

  const renderModalBody = () => {
    if (modalLabel === "pricpSet") {
      return (
        <>
          <div>Price</div>
          {data[1]?.price && <div>{data[1]?.price}</div>}

          <input
            type="number"
            value={fuelPrice}
            placeholder="Please Enter price"
            className="w-full custom-input"
            onChange={(e) => setFuelPrice(e.target.value)}
          />

          <button
            className="prmiary-btn"
            onClick={() => {
              sendMessage(1, fuelPrice, "amount");
              toast.success("Price set");
            }}
          >
            Set price
          </button>
        </>
      );
    }

    if (modalLabel === "startStopFueling") {
      return (
        <>
          <div>Manual Filling</div>
          <div className="w-full flex justify-between buttonsDiv">
            {" "}
            <button
              className="prmiary-btn startFuel"
              onClick={() => {
                sendMessage(2);
                toast.success("Start Manual fueling");
              }}
            >
              Start Fueling
            </button>
            <button
              className="prmiary-btn"
              onClick={() => {
                sendMessage(3);
                toast.success("Stop fueling");
              }}
            >
              Stop Fueling
            </button>
          </div>
          <div>Preset by Amount</div>
          <input
            type="number"
            placeholder="Please Enter Preset by Amount"
            className="w-full custom-input"
            value={totalAmount}
            onChange={(e) => setTotalAmount(e.target.value)}
          />

          <div className="w-full flex justify-between buttonsDiv">
            {" "}
            <button
              className="prmiary-btn startFuel"
              onClick={() => {
                sendMessage(5, totalAmount, "amount");
                toast.success("Start fueling");
              }}
            >
              Start Fueling
            </button>
            {/* <button
              className="prmiary-btn"
              onClick={() => {
                sendMessage(4);
                toast.success("Stop fueling");
              }}
            >
              Stop Fueling
            </button> */}
          </div>

          <div>Preset by Volume</div>
          <input
            type="number"
            placeholder="Please Enter Preset by Volume"
            className="w-full custom-input"
            value={totalVolume}
            onChange={(e) => setTotalVolume(e.target.value)}
          />

          <div className="w-full flex justify-between buttonsDiv">
            {" "}
            <button
              className="prmiary-btn startFuel"
              onClick={() => {
                sendMessage(4, totalVolume, "volume");
                toast.success("Start fueling");
              }}
            >
              Start Fueling
            </button>
            {/* <button
              className="prmiary-btn"
              onClick={() => {
                sendMessage(3);
                toast.success("Stop fueling");
              }}
            >
              Stop Fueling
            </button> */}
          </div>
        </>
      );
    }
    if (modalLabel === "readPrice") {
      return (
        <>
          <div className="w-full flex justify-between  p-1">
            <div>Price </div>
            <div> {data[6]?.price}</div>
            {/* {data ? <Shimmer/> : <div> {data[6]?.price}</div>} */}

          </div>
        </>
      );
    }
    if (modalLabel === "readTotalAccumulation") {
      return (
        <>
          <div className="w-full flex justify-between border-b p-1">
            <div>Total Volume of Fuel </div>
            <div> {data[9]?.volume}</div>
          </div>
          <div className="w-full flex justify-between  p-1">
            <div>Total sale Amount </div>
            <div> {data[9]?.amount}</div>
          </div>
        </>
      );
    }
    console.log("data", data);

    if (modalLabel === "readDeleteShiftTotal") {
      return (
        <>
          <div className="w-full flex justify-between border-b p-1">
            <div>Total Volume of Fuel </div>
            <div> {data[7]?.volume}</div>
          </div>
          <div className="w-full flex justify-between border-b p-1">
            <div>Total Sale Amount </div>
            <div> {data[7]?.amount}</div>
          </div>

          <div className="flex">
            <button
              className="prmiary-btn"
              onClick={() => {
                sendMessage(8);
                toast.success("Clear ");
              }}
            >
              Clear Data{" "}
            </button>
          </div>
        </>
      );
    }

    if (modalLabel === "getfuelingdata") {
      return (
        <>
          <div className="w-full flex justify-between border-b p-1">
            <div>Amount Fueling Data</div>
            <div> {data[11]?.amount}</div>
          </div>
          <div className="w-full flex justify-between  p-1">
            <div>Volume Fueling Data </div>
            <div> {data[11]?.volume}</div>
          </div>
        </>
      );
    }

    if (modalLabel === "getdatetime") {
      return (
        <>
          <div className="w-full flex justify-between border-b p-1">
            <div>Date </div>
            <div> {data[14]?.date}</div>
          </div>
          <div className="w-full flex justify-between  p-1">
            <div>Time </div>
            <div> {data[14]?.time}</div>
          </div>
        </>
      );
    }
   
    return "hello";
  };

  const handleModalOpen = (name, label) => {
    if (label === "readPrice") {
      sendMessage(6);
    }
    if (label === "readDeleteShiftTotal") {
      sendMessage(7);
    }
    if (label === "readTotalAccumulation") {
      sendMessage(9);
    }
    if (label === "readState") {
      sendMessage(10);
    }
    if (label === "getfuelingdata") {
      sendMessage(11);
    }
    if (label === "pausesolenoidvalve") {
      sendMessage(12);
    }
    if (label === "getdatetime") {
      sendMessage(14);
    }
    if (label !== "pausesolenoidvalve") {
      setModalOpen(true);
    } else {
      toast.success("Paused fuel");
    }
    setModalTitle(name);
    setModalLabel(label);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setFuelPrice(0);
    setTotalAmount(0);
    setTotalVolume(0);
  };

  const listData = [
    {
      label: "pricpSet",
      name: "Price Set",
      icon: PriceSetIcon,
    },
    {
      label: "startStopFueling",
      name: "Start/Stop Fueling",
      icon: FuelStart,
    },
    {
      label: "readPrice",
      name: "Read Price",
      icon: ReadPriceIcon,
    },
    {
      label: "readTotalAccumulation",
      name: "Read Total Accumulation",
      icon: ReadTotalAccIcon,
    },
    {
      label: "readDeleteShiftTotal",
      name: "Read/Delete Shift Total",
      icon: ReadDeleteShiftIcon,
    },
    {
      label: "readState",
      name: "Read State",
      icon: ReadStateIcon,
    },

    {
      label: "getfuelingdata",
      name: "Get Fueling Data",
      icon: GetFuelingDataIcon,
    },
    {
      label: "getdatetime",
      name: "Get Date & Time",
      icon: GetDateTimeIcon,
    },
    {
      label: "pausesolenoidvalve",
      name: "Pause Solenoid Valve",
      icon: PauseSolenoidValveIcon,
    },
    {
      label: "resumesolenoidvalve",
      name: "Resume Solenoid Valve",
      icon: ResumeSolenoidValveIcon,
    },
  ];

  return (
    <>
      <div className="flex h-full items-center justify-center text-center p-40">
        <div className="w-full">
          <h1>Fuel System</h1>

          <div className="flex h-full items-center justify-center text-center w-full flex-wrap">
            {listData.map((item, index) => (
              <div key={index} className="card ">
                <div className="card-body" onClick={() => handleModalOpen(item.name, item.label)}>
                  <img src={item.icon} alt={item.icon} />
                  <div className="mt-10">{item.name}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onClose={handleModalClose}
        title={modalTitle}
        // firstBtnTitle={"Set price"}
        // secondBtnTitle={null}
      >
        <div className="flex flex-wrap">{renderModalBody()}</div>
      </Modal>
    </>
  );
}
export default Dashboard;
